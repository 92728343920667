<template>
  <el-dialog
      class="login-modal"
      :close-on-click-modal="false"
      :title="$t('login.login')"
      :visible.sync="v_login_form"
      width="30%"
  >
    <el-form ref="loginForm" :model="login_form" label-width="80px">
      <el-form-item :label="$t('login.phone')">
        <el-input v-model="login_form.phone"></el-input>
      </el-form-item>
      <el-form-item :label="$t('login.password')">
        <el-input show-password v-model="login_form.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="doLogin">{{ $t('login.login') }}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "Login",
  data: function () {
    return {
      v_login_form: false,
      login_form: {}
    }
  },
  methods: {
    doLogin() {
      let me = this
      let data = {
        username: me.login_form.phone,
        password: me.login_form.password
      }
      me.axios.post('/login', data).then((res) => {
        console.log(res)
        localStorage.setItem('token', res.token)
        me.axios.get("/settings/user").then((res1)=>{
          localStorage.setItem('user_info',JSON.stringify(res1.data))
          me.v_login_form = false
          me.$emit('login-result', 'ok')
        })
      }).catch(() => {
        me.$emit('login-result', 'fail')
      })
    }
  }
}
</script>

<style scoped>

</style>
